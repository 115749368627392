import React, { useEffect, useState } from "react";
import "./UserMainDashboard.css";
import { UserFooter, UserHeader } from "../../components";
import { useGlobalContext } from "../../../../context/context";
import axios from "axios";

const UserMainDashboard = () => {
  const {
    baseUrl,
    totalAmount,
    setTotalAmount,
    userData,
    setUserData,
    fetchUpdatedUser,
  } = useGlobalContext();
  const [approvedTransfer, setApprovedTransfer] = useState([]);
  const [pendingTransfer, setPendingTransfer] = useState([]);
  const [totalDeposit, setTotalDeposit] = useState([]);
  const [pendingDeposit, setPendingDeposit] = useState([]);

  const { profit, country } = userData;

  const user = JSON.parse(sessionStorage.getItem("user"));
  const accessToken = JSON.parse(sessionStorage.getItem("userToken"));
  const { _id } = user;

  // FETCH THE MOST UPDATED USER DETAILS
  useEffect(() => {
    fetchUpdatedUser(_id, accessToken);
    // eslint-disable-next-line
  }, [_id]);

  const getTotalAmount = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/transfer/${_id}`, {
        headers: { token: accessToken },
      });
      if (response.status === 200) {
        setPendingTransfer(
          response.data.transfers.filter(
            (transfer) => transfer.status === false
          )
        );
        setApprovedTransfer(
          response.data.transfers.filter((transfer) => transfer.status === true)
        );
      }
    } catch (error) {}
  };

  const getTotalAmountDeposit = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/deposit/${_id}`, {
        headers: { token: accessToken },
      });
      if (response.status === 200) {
        setTotalDeposit(
          response.data.deposit.filter((deposit) => deposit.status === true)
        );
        setPendingDeposit(
          response.data.deposit.filter((deposit) => deposit.status === false)
        );
      }
    } catch (error) {}
  };

  const getUserData = async () => {
    try {
      const userInfo = await axios.get(`${baseUrl}/api/users/${_id}`, {
        headers: { token: accessToken },
      });
      if (userInfo.status === 200) {
        setUserData(userInfo.data);
      }
    } catch (error) {}
  };

  // CALCULATE TOTAL PENDING DEPOSIT
  const totalPending = pendingDeposit.reduce((acc, curr) => {
    return acc + Number(curr.amount);
  }, 0);

  // CALCULATE TOTAL DEPOSIT
  const totalApproved = totalDeposit.reduce((acc, curr) => {
    return acc + Number(curr.amount);
  }, 0);

  // CALCULATE TOTAL PENDING TRANSFERS
  const totalPendingTF = pendingTransfer.reduce((acc, curr) => {
    return acc + Number(curr.amount);
  }, 0);

  // CALCULATE TOTAL APPROVED TRANSFERS
  const totalApprovedTF = approvedTransfer.reduce((acc, curr) => {
    return acc + Number(curr.amount);
  }, 0);

  useEffect(() => {
    getTotalAmount();
    getTotalAmountDeposit();
    getUserData();
    // eslint-disable-next-line
  }, []);

  return (
    <section className="user_main_dashboard">
      <UserHeader
        transferAmount={totalApprovedTF}
        depositAmount={totalApproved}
      />
      <div className="mrkt-details flex">
        <div>
          <p>Profit</p>
          <h2 className="success">
            {country?.symbol}
            {profit ? profit.toLocaleString("en-US") : "0"}
          </h2>
        </div>
        <div>
          <p>Total Deposit Amount</p>
          <p>
            {country?.symbol}
            {totalDeposit ? Number(totalApproved).toLocaleString(`en-US`) : "0"}
          </p>
        </div>
        <div>
          <p>Pending Transfers</p>
          <p>
            {country?.symbol}
            {totalDeposit
              ? Number(totalPendingTF).toLocaleString(`en-US`)
              : "0"}
          </p>
        </div>
        <div>
          <p>Approved Transfer</p>
          <h2 className="danger">
            {country?.symbol}
            {totalApprovedTF.toLocaleString("en-US")}
          </h2>
        </div>
      </div>
      <UserFooter />
    </section>
  );
};

export default UserMainDashboard;
