import React, { useEffect, useState } from "react";
import Button from "../Btn/Btn";
import copy from "copy-to-clipboard";
import "./Deposit.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColorRing } from "react-loader-spinner";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserFooter, UserHeader } from "../../components";
import { useGlobalContext } from "../../../../context/context";

const Deposit = () => {
  const [walletAddress, setWalletAddress] = useState([
    { walletName: "bitcoin", address: "12345678" },
    { walletName: "usdt", address: "12345678" },
    { walletName: "ethereum", address: "12345678" },
  ]);
  const [payment, setPayment] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [loader, setLoader] = useState(false);
  const { baseUrl, userData, getUser } = useGlobalContext();
  const accessToken = JSON.parse(sessionStorage.getItem("userToken"));
  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState(null);
  const { firstName, lastName, email, _id } = userData;
  const [formData, setFormData] = useState({
    images: [],
  });

  const navigate = useNavigate();
  const notify = () => toast.success("Wallet Address Copied");
  const depositNotify = () => toast.success("Deposit Successful");

  const [uploadedImage, setUploadedImage] = useState(null);
  const handleDeposit = () => {
    const depositForm = new FormData();
    depositForm.append("amount", amount);
    depositForm.append("status", false);
    depositForm.append("userId", _id);
    depositForm.append("transactiontype", paymentMethod);
    depositForm.append("name", `${firstName} ${lastName}`);
    depositForm.append("email", email);
    for (let i = 0; i < formData.images.length; i++) {
      depositForm.append("image", formData.images[i]);
    }
    setLoader(true);
    axios
      .post(`${baseUrl}/api/deposit`, depositForm, {
        headers: {
          token: accessToken,
        },
      })
      .then((data) => {
        if (data.status === 200) {
          depositNotify();
          setLoader(false);
          setTimeout(() => {
            navigate("/dash/transactions");
          }, 3000);
        }
      })
      .catch((error) => {
        setLoader(false);
        if (
          error.response?.data?.message ===
          "deposit validation failed: amount: Path `amount` is required."
        ) {
          toast.error(
            "deposit validation failed. amount and proof image is required"
          );
        }
      });
  };

  const toggleMethod = (wallet) => {
    if (wallet.walletName === "bitcoin") {
      copy(wallet.address);
      setAddress(wallet.address);
      setPayment(wallet.walletName);
    } else if (wallet.walletName === "usdt") {
      copy(wallet.address);
      setAddress(wallet.address);
      setPayment(wallet.walletName);
    } else if (wallet.walletName === "ethereum") {
      copy(wallet.address);
      setAddress(wallet.address);
      setPayment(wallet.walletName);
    }
    notify();
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, images: e.target.files });
    if (e.target.files && e.target.files[0]) {
      setUploadedImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  // async function getAddresses() {
  //   const addresses = await client.fetch('*[_type == "addresses"]');
  //   setWalletAddress(addresses);
  // }

  useEffect(() => {
    getUser(accessToken);
    // getAddresses();
  }, []);

  return (
    <>
      <section className="deposits_container">
        <UserHeader />
        <p className="sub-p">Deposit</p>

        <div className="withdraw_sect_hero">
          <h2>Make All Deposits Here</h2>
          <h3>Amount</h3>
          <input
            type="text"
            name="amount"
            onChange={(e) => setAmount(e.target.value)}
          />

          <h3>Select Deposit Method</h3>
          <div className="methods_sect">
            <Button
              background={`${paymentMethod === "bank" ? "#071278" : ""}`}
              color={`${paymentMethod === "bank" ? "white" : ""}`}
              id={0}
              title={"Bank Transfer"}
              action={() => setPaymentMethod("bank")}
            />
            <Button
              background={`${paymentMethod === "crypto" ? "#071278" : ""}`}
              color={`${paymentMethod === "crypto" ? "white" : ""}`}
              id={0}
              title={"Cryptocurrencies"}
              action={() => setPaymentMethod("crypto")}
            />
          </div>
          {paymentMethod === "crypto" && (
            <h3>Click prefer Wallet Address to Deposit</h3>
          )}
          {paymentMethod === "crypto" && (
            <div className="methods_sect">
              {walletAddress.map((item, index) => {
                const { walletName } = item;
                return (
                  <div key={index}>
                    <Button
                      background={`${payment === walletName ? "#071278" : ""}`}
                      color={`${payment === walletName ? "white" : ""}`}
                      id={index + 1}
                      action={() => toggleMethod(item)}
                      title={walletName}
                    />
                  </div>
                );
              })}
            </div>
          )}
          <div className="deposit_proof_sect">
            <h3>
              Please reach out to customer care for payment through bank
              transfer
            </h3>
            {paymentMethod === "crypto" && <h3>Wallet Address: {address}</h3>}
            <h3>Click to Send Proof of Payment</h3>
            <input
              id="proof"
              name="images"
              type="file"
              style={{ display: "none" }}
              multiple={true}
              onChange={handleImageChange}
            />
            <label
              className="button"
              style={{ background: "#071278", color: "#fff", fontSize: 14 }}
              htmlFor="proof"
            >
              Send Proof
            </label>
            <img className="proof" src={uploadedImage} alt="" />
          </div>
          <button onClick={handleDeposit} className="button" id={"submit_btn"}>
            <span>Make Deposit</span>
            {loader && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )}
          </button>
        </div>
        <UserFooter />
      </section>
      <ToastContainer />
    </>
  );
};

export default Deposit;
