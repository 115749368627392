import React, { useEffect } from "react";
import "./Withdraw.css";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserHeader } from "../../components";
import { useGlobalContext } from "../../../../context/context";
import OTPModal from "../Modal";

function Withdraw() {
  const getSuccessfulDepositsAmount = JSON.parse(
    sessionStorage.getItem("deposits")
  );
  const withdraw = JSON.parse(sessionStorage.getItem("withdraw"));

  const { handleSubmit, register } = useForm();
  const { userData, getUser, baseUrl } = useGlobalContext();
  const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
  const navigate = useNavigate();
  const { firstName, transferStep, profit, bonus, status } = userData;
  const [otp, setOtp] = useState(0);
  const total = getSuccessfulDepositsAmount + profit + bonus - withdraw;
  const [loading, setLoading] = useState(false);

  const handleSteps = (data) => {
    data.userId = userData._id;
    let { amount, bankName, accountType, accountNumber, accountName } = data;

    if (status === "false") {
      toast.error("User cant make withdrawal now.");
      setTimeout(() => {
        navigate("/wallet");
      }, 3000);
    } else if (
      amount === "" ||
      bankName === "" ||
      accountName === "" ||
      accountNumber === "" ||
      accountType === ""
    ) {
      toast.error("All Inputs Field must be valid");
    } else if (amount > total) {
      toast.error("Insufficient Funds");
    } else if (
      transferStep !== 13 &&
      amount >= 2500 &&
      total >= amount &&
      bankName !== "" &&
      accountName !== "" &&
      accountType !== "" &&
      accountNumber !== ""
    ) {
      toast.success("Withdrawal in Progress");
      setTimeout(() => {
        setOtp(1);
      }, 2000);
    }
    if (
      transferStep === 13 &&
      amount >= 2500 &&
      total >= amount &&
      bankName !== "" &&
      accountName !== "" &&
      accountType !== "" &&
      accountNumber !== ""
    ) {
      setOtp(0);
      setLoading(true);
      axios
        .post(`${baseUrl}transfer`, data, {
          headers: { token: accessToken },
        })
        .then((data) => {
          toast.success("Withdrawal Successful");
          setLoading(false);
          setTimeout(() => {
            navigate("/transactions");
          }, 2000);
        })
        .catch((error) => {
          toast.error("Withdrawal Failed Contact Customer Care");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    // getUser(accessToken);
    if (transferStep === 2) {
      navigate("/account");
    } else if (transferStep === 4) {
      navigate("/account");
    } else if (transferStep === 6) {
      navigate("/account");
    } else if (transferStep === 8) {
      navigate("/account");
    } else if (transferStep === 10) {
      navigate("/account");
    } else if (transferStep === 12) {
      navigate("/account");
    }
  }, []);
  return (
    <section className="withdraw_sect">
      <UserHeader />
      <div className="withdraw_sect_hero">
        <h2>Enter Withdrawal Details</h2>
        <h3>Amount</h3>
        <input
          type="text"
          {...register("amount")}
          placeholder="Put Amount In $"
        />
        <h3>Bank Name</h3>
        <input type="text" {...register("bankName")} />
        <h3>Account Type</h3>
        <input type="text" {...register("accountType")} />
        <h3>Name on Account</h3>
        <input type="text" {...register("accountName")} />
        <h3>Account Number</h3>
        <input type="text" {...register("accountNumber")} />
        <button
          onClick={handleSubmit((data) => handleSteps(data))}
          className="btn"
        >
          {loading ? (
            <ColorRing
              visible={true}
              height="30"
              width="30"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass="blocks-wrapper"
              colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
            />
          ) : (
            "Make Withdrawal"
          )}
        </button>
      </div>
      {otp === 1 ? <OTPModal /> : ""}
      <ToastContainer />
    </section>
  );
}

export default Withdraw;
