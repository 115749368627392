import React from "react";
import telegram from "../assets/telegram.svg";

export const Telegram = () => {
  return (
    <a
      target="_blank"
      href="https://t.me/paymentserveronline0"
      className="telegram_wrapper"
    >
      <img src={telegram} alt="" />
    </a>
  );
};
