import React, { useEffect, useState } from "react";
import "./Transactions.css";
import axios from "axios";
import { UserFooter, UserHeader } from "../../components";
import { useGlobalContext } from "../../../../context/context";

const Transactions = () => {
  const { baseUrl } = useGlobalContext();
  const [transferTransactions, setTransferTransactions] = useState([]);
  const [depositTransactions, setDepositTransactions] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const accessToken = JSON.parse(sessionStorage.getItem("userToken"));
  const userId = user._id;
  const { country } = user;

  const getDepositTransactions = async () => {
    try {
      const res = await axios.get(`${baseUrl}/api/deposit/${userId}`, {
        headers: { token: accessToken },
      });

      if (res.status === 200) {
        setDepositTransactions(res.data.deposit);
      }
    } catch (error) {}
  };

  const getWithdrawalsTransactions = async () => {
    try {
      const res = await axios.get(`${baseUrl}/api/transfer/${userId}`, {
        headers: { token: accessToken },
      });
      if (res.status === 200) {
        setTransferTransactions(res.data.transfers);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDepositTransactions();
    getWithdrawalsTransactions();
  }, []);

  return (
    <section className="user_transactions">
      <UserHeader />
      <div className="transaction-table-container">
        <p className="sub-p">Transaction Table</p>
        <p className="sub-p">All Transactions are saved here for refrencing.</p>
        <div className="transaction-table">
          <div className="transaction_table_header flex">
            <div className="transaction_table_header_item_one flex">
              <p className="sub-p">Type</p>
              <p className="sub-p">Amount</p>
            </div>
            <div className="transaction_table_header_item_two flex">
              <p className="sub-p">Time</p>
              <p className="sub-p">Date</p>
              <p className="sub-p">Status</p>
            </div>
          </div>
          {depositTransactions.length >= 0 &&
            depositTransactions.map((item, idx) => {
              return (
                <div
                  className="transaction_table_header transaction_table_body flex"
                  key={idx}
                >
                  <div className="transaction_table_header_item_one flex">
                    <p className="sub-p">
                      {item.transactiontype === "crypto" || "bank"
                        ? "Deposit"
                        : ""}
                    </p>
                    <p className="sub-p">{item.amount}</p>
                  </div>
                  <div className="transaction_table_header_item_two flex">
                    <p className="sub-p">{item.createdAt.slice(11, 16)}</p>
                    <p className="sub-p">{item.createdAt.slice(0, 10)}</p>
                    <p className="sub-p">
                      {item.status ? "Approved" : "Pending"}
                    </p>
                  </div>
                </div>
              );
            })}
          {transferTransactions.length >= 0 &&
            transferTransactions.map((item, idx) => {
              return (
                <div
                  className="transaction_table_header transaction_table_body flex"
                  key={idx}
                >
                  <div className="transaction_table_header_item_one flex">
                    <p className="sub-p">
                      {item.transactiontype === "deposit"
                        ? "Deposit"
                        : "Transfer"}
                    </p>
                    <p className="sub-p">
                      {country?.symbol}
                      {item.amount}
                    </p>
                  </div>
                  <div className="transaction_table_header_item_two flex">
                    <p className="sub-p">{item.createdAt.slice(11, 16)}</p>
                    <p className="sub-p">{item.createdAt.slice(0, 10)}</p>
                    <p className="sub-p">
                      {item.status ? "Approved" : "Pending"}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <UserFooter />
    </section>
  );
};

export default Transactions;
