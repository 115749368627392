import axios from "axios";
import React, { useContext, useState } from "react";
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [userData, setUserData] = useState([]);
  const baseUrl = "https://netcapitalonline-api.onrender.com";
  // const baseUrl = "http://localhost:5000";
  const [kycStatus, setKycStatus] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalAmount, setTotalAmount] = useState("");
  const [userNav, setUserNav] = useState(false);
  const [headerNav, setHeaderNav] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [terms, setTerms] = useState(false);
  const [address, setAddress] = useState(0);
  const [copy, setCopy] = useState(false);
  const [random, setRandom] = useState("");
  const user = JSON.parse(sessionStorage.getItem("user"));

  const toggleNave = (e) => {
    switch (e.target.id) {
      case "nav":
        setUserNav(!userNav);
        break;
      case "header_nav":
        setHeaderNav(!headerNav);

        break;
      case "tab":
        if (e.target.className == "id_1") {
          setActiveTab(1);
        }
        if (e.target.className == "id_2") {
          setActiveTab(2);
        }
        if (e.target.className == "id_3") {
          setActiveTab(3);
        }
        break;
      case "terms":
        setTerms(!terms);
        break;
      case "address":
        if (e.target.className === "bitcoin") {
          setAddress(1);
        } else if (e.target.className === "usdt") {
          setAddress(2);
        }
        break;
    }
  };

  // KYC STATus
  const getUserKYC = (token) => {
    axios
      .get(`${baseUrl}/api/kyc/${user?._id}`, { headers: { token: token } })
      .then((response) => {
        setKycStatus(response.data);
      })
      .catch((error) => {});
  };

  // Get User Details

  const getUser = (token, id) => {
    axios
      .get(`${baseUrl}/api/users/${id || user?._id}`, {
        headers: { token },
      })
      .then((data) => {
        if (data.status === 200) {
          setUserData(data.data);
          sessionStorage.setItem("user", JSON.stringify(data.data));
        }
      })
      .catch((error) => {});
  };

  const fetchUpdatedUser = async (userId, accessToken) => {
    try {
      const response = await axios.get(`${baseUrl}/api/users/${userId}`, {
        headers: { token: accessToken },
      });
      if (sessionStorage.getItem("user")) {
        sessionStorage.removeItem("user");
        sessionStorage.setItem("user", JSON.stringify(response.data));
      } else {
        sessionStorage.setItem("user", JSON.stringify(response.data));
      }
    } catch (error) {}
  };

  return (
    <AppContext.Provider
      value={{
        fetchUpdatedUser,
        loading,
        setLoading,
        userNav,
        setUserNav,
        toggleNave,
        headerNav,
        setHeaderNav,
        activeTab,
        terms,
        random,
        setRandom,
        copy,
        setCopy,
        baseUrl,
        setTotalAmount,
        totalAmount,
        setUserData,
        userData,
        kycStatus,
        setKycStatus,
        getUser,
        getUserKYC,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };
