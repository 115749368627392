import React, { useEffect } from "react";
import "./Profile.css";
import { SemiHeader, UserFooter } from "../../components";
import { useGlobalContext } from "../../../../context/context";

const Profile = () => {
  const { userData, getUser } = useGlobalContext();

  const accessToken = JSON.parse(sessionStorage.getItem("userToken"));
  const {
    firstName,
    lastName,
    userName,
    country,
    gender,
    maritalstatus,
    age,
    dob,
    phone,
    email,
    address,
    city,
    zipCode,
    accountNum,
  } = userData;

  useEffect(() => {
    getUser(accessToken);
  }, []);

  return (
    <section className="user_profile">
      <SemiHeader title={"Your Profile"} />

      <div className="user_profile_details">
        <div>
          <h3>First Name</h3>
          <p className="sub-p">{firstName}</p>
        </div>
        <div>
          <h3>Last Name</h3>
          <p className="sub-p">{lastName}</p>
        </div>
        <div>
          <h3>User Name</h3>
          <p className="sub-p">{userName}</p>
        </div>
        <div>
          <h3>Gender</h3>
          <p className="sub-p">{!gender ? "Not Stated Yet" : gender}</p>
        </div>
        <div>
          <h3>Age</h3>
          <p className="sub-p">{!age ? "Not Stated Yet" : age}</p>
        </div>
        <div>
          <h3>Marital Status</h3>
          <p className="sub-p">
            {!maritalstatus ? "Not Stated Yet" : maritalstatus}
          </p>
        </div>
        <div>
          <h3>Contact</h3>
          <p className="sub-p">{phone}</p>
        </div>
        <div>
          <h3>Date of Birth</h3>
          <p className="sub-p">{!dob ? "Not Stated Yet" : dob.slice(0, 10)}</p>
        </div>
        <div>
          <h3>Email</h3>
          <a className="link" href="mailto:miens@gmail.com">
            {email}
          </a>
        </div>
        <div>
          <h3>Address</h3>
          <p className="sub-p">{!address ? "Not Stated Yet" : address}</p>
        </div>
        <div>
          <h3>City</h3>
          <p className="sub-p">{!city ? "Not Stated Yet" : city}</p>
        </div>
        <div>
          <h3>Zip Code</h3>
          <p className="sub-p">{!zipCode ? "Not Stated Yet" : zipCode}</p>
        </div>
        <div>
          <h3>Country</h3>
          <p className="sub-p">{country?.name}</p>
        </div>
        <div>
          <h3>Currency</h3>
          <p className="sub-p">{country?.symbol}</p>
        </div>
        <div>
          <h3>Acoount Number</h3>
          <p className="sub-p">{accountNum}</p>
        </div>
      </div>
      <UserFooter />
    </section>
  );
};

export default Profile;
