import React, { useState } from "react";
import "./Transfer.css";
import { UserFooter, UserHeader } from "../../components";
import { useGlobalContext } from "../../../../context/context";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ColorRing } from "react-loader-spinner";
import { TransferModal } from "../TransferModal";
import OTPModal from "../Modal";

const Transfer = () => {
  const [check, setChecked] = useState(false);
  const getSuccessfulDepositsAmount = JSON.parse(
    sessionStorage.getItem("deposits")
  );
  const withdraw = JSON.parse(sessionStorage.getItem("withdraw"));
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [type, setType] = useState("");
  const [amount, setAmount] = useState("");
  const details = {
    name,
    type,
    amount,
    number,
  };
  const { userData, getUser, baseUrl, getUserKYC, kycStatus } =
    useGlobalContext();
  const accessToken = JSON.parse(sessionStorage.getItem("userToken"));
  const navigate = useNavigate();
  const { firstName, transferStep, profit, bonus, status } = userData;
  const [otp, setOtp] = useState(0);
  const total = getSuccessfulDepositsAmount + profit + bonus - withdraw;
  const [loading, setLoading] = useState(false);
  const transferData = JSON.parse(sessionStorage.getItem("transferData"));
  const { handleSubmit, register } = useForm();

  const handleSteps = (data) => {
    sessionStorage.setItem("transferData", JSON.stringify(data));
    data.userId = userData._id;
    let { amount, bankName, accountType, accountNumber, accountName } = data;
    setAmount(amount);
    setName(accountName);
    setNumber(accountNumber);
    setType(accountType);

    if (status === "false") {
      toast.error("User cant make withdrawal now.");
      setTimeout(() => {
        navigate("/dash/dashboard");
      }, 3000);
    } else if (
      amount === "" ||
      bankName === "" ||
      accountName === "" ||
      accountNumber === "" ||
      accountType === ""
    ) {
      toast.error("All Inputs Field must be valid");
    } else if (amount > total) {
      toast.error("Insufficient Funds");
    } else if (
      transferStep !== 13 &&
      total >= amount &&
      bankName !== "" &&
      accountName !== "" &&
      accountType !== "" &&
      accountNumber !== ""
    ) {
      toast.success("Withdrawal in Progress");
      setTimeout(() => {
        setChecked(true);
      }, 2000);
    }
    if (
      transferStep === 13 &&
      amount >= 2500 &&
      total >= amount &&
      bankName !== "" &&
      accountName !== "" &&
      accountType !== "" &&
      accountNumber !== ""
    ) {
      setOtp(0);
      setLoading(true);
      axios
        .post(`${baseUrl}/api/transfer`, data, {
          headers: { token: accessToken },
        })
        .then((data) => {
          toast.success("Withdrawal Successful");
          setLoading(false);
          setTimeout(() => {
            navigate("/dash/transactions");
          }, 2000);
        })
        .catch((error) => {
          toast.error("Withdrawal Failed Contact Customer Care");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (kycStatus?.length === 0) {
      toast.error("User Cant Make Withdrawal Now, Please Verify KYC");
      setTimeout(() => {
        navigate("/dash/verify");
      }, 5000);
    }
    if (kycStatus[0]?.status === false) {
      toast.error("Please Reach Out To Customer Care For Full Verification");
      setTimeout(() => {
        navigate("/dash/dashboard");
      }, 5000);
    }
    getUserKYC(accessToken);
    getUser(accessToken);
    if (transferStep === 2) {
      navigate("/dash/dashboard");
    } else if (transferStep === 4) {
      navigate("/dash/dashboard");
    } else if (transferStep === 6) {
      navigate("/dash/dashboard");
    } else if (transferStep === 8) {
      navigate("/dash/dashboard");
    } else if (transferStep === 10) {
      navigate("/dash/dashboard");
    } else if (transferStep === 12) {
      navigate("/dash/dashboard");
    }
  }, []);
  return (
    <>
      <section className="transfer_sect">
        <h2 className="sub-p">Transfers</h2>
        <div className="transfer_details_container">
          <h3 className="sub-p">Bank Name</h3>
          <input
            defaultValue={transferData?.bankName}
            type="text"
            placeholder="Bank Name"
            {...register("bankName")}
          />
          <h3 className="sub-p">Account Name</h3>
          <input
            defaultValue={transferData?.accountName}
            type="text"
            placeholder="Account Name"
            {...register("accountName")}
          />
          <h3 className="sub-p">Account Number</h3>
          <input
            defaultValue={transferData?.accountNumber}
            type="text"
            placeholder="Account Number"
            {...register("accountNumber")}
          />
          <h3 className="sub-p">Account Type</h3>
          <input
            defaultValue={transferData?.accountType}
            type="text"
            placeholder="Account Type"
            {...register("accountType")}
          />
          <h3 className="sub-p">Iban Number</h3>
          <input
            defaultValue={transferData?.ibanNumber}
            type="text"
            placeholder="Iban Number"
            {...register("ibanNumber")}
          />
          <h3 className="sub-p">Swift Code</h3>
          <input
            defaultValue={transferData?.swiftcode}
            type="text"
            placeholder="Swift Code"
            {...register("swiftcode")}
          />
          <h3 className="sub-p">Amount</h3>
          <input
            type="text"
            placeholder="Amount"
            {...register("amount")}
            defaultValue={transferData?.amount}
          />
          <button
            style={{ marginTop: 30 }}
            className="btn"
            onClick={handleSubmit((data) => handleSteps(data))}
          >
            {loading ? (
              <ColorRing
                visible={true}
                height="25"
                width="25"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            ) : (
              "Confirm Transfer"
            )}
          </button>
        </div>
        <UserHeader />
        <UserFooter />
        <ToastContainer />
        {check && (
          <TransferModal
            details={details}
            handleSteps={handleSteps}
            setModal={setOtp}
            setChecked={setChecked}
          />
        )}
        {otp === 1 && <OTPModal />}
      </section>
    </>
  );
};

export default Transfer;
