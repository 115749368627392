import React, { useState } from "react";
import "./KYC.css";
import { SemiHeader, UserFooter } from "../../components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useGlobalContext } from "../../../../context/context";
import { toast, ToastContainer } from "react-toastify";

const KYC = () => {
  const { baseUrl } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const user = JSON.parse(sessionStorage.getItem("user"));
  const { _id, email } = user;
  const accessToken = JSON.parse(sessionStorage.getItem("userToken"));

  const [formData, setFormData] = useState({
    name: "",
    number: "",
    back: [],
    front: [],
    userId: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleFrontImageChange = (e) => {
    setFormData({ ...formData, front: e.target.files });
  };
  const handleBackImageChange = (e) => {
    setFormData({ ...formData, back: e.target.files });
  };

  const notify = () => {
    toast("Documents uploaded. Reach out to customer care for verification");
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    data.append("name", formData.name);
    data.append("userId", _id);
    data.append("email", email);
    data.append("number", formData.number);
    for (let i = 0; i < formData.front.length; i++) {
      data.append("front", formData.front[i]);
    }
    for (let i = 0; i < formData.back.length; i++) {
      data.append("back", formData.back[i]);
    }

    try {
      const kycPost = await axios.post(`${baseUrl}/api/kyc`, data, {
        headers: { token: accessToken },
      });
      if (kycPost.status === 200) {
        notify();
        setLoading(false);
        setTimeout(() => {
          navigate("/dash/dashboard");
        }, 3500);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <section className="kyc">
      <SemiHeader title="Bank Account" />
      <div className="kyc-verification">
        <h3>KYC Verification</h3>
        <p className="sub-p">Fill the form to Verify Account</p>
        <form
          action="#"
          className="form"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <label htmlFor="#">
            <input
              type="text"
              name="name"
              placeholder="ID Name"
              onChange={handleChange}
              value={formData.name}
            />
          </label>

          <label htmlFor="#">
            <input
              type="text"
              name="number"
              placeholder="ID Number"
              onChange={handleChange}
              value={formData.number}
            />
          </label>
          <label htmlFor="#">
            <input
              type="file"
              name="front"
              placeholder="Front"
              onChange={handleFrontImageChange}
              multiple
            />
          </label>
          <label htmlFor="#">
            <input
              type="file"
              name="back"
              placeholder="Back"
              onChange={handleBackImageChange}
              multiple
            />
          </label>
          <label htmlFor="#">
            <button className="btn">{loading ? "loading..." : "Submit"}</button>
          </label>
        </form>
      </div>
      <UserFooter />
      <ToastContainer />
    </section>
  );
};

export default KYC;
