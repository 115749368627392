import React from "react";
import "./Cards.css";
import { SemiHeader } from "../../components";
import { AiOutlinePlus } from "react-icons/ai";

const Cards = () => {
  return (
    <section className="card_sect">
      <SemiHeader title={"Request Cards"} />
      <div className="create_card">
        <div className="flex">
          <h3>Click Icon to Add Card Details</h3>
          <AiOutlinePlus />
        </div>
        <h1>Card Request Coming Soon</h1>
      </div>
    </section>
  );
};

export default Cards;
