import { useEffect } from "react";
import "./App.css";
import Aos from "aos";
import "aos/dist/aos.css";
import telegram from "./assets/telegram.svg";

import {
  About,
  Contacts,
  Faq,
  ForgetPass,
  Home,
  Login,
  Privacy,
  Register,
  Terms,
  VerifyEmail,
} from "./pages";
import { Routes, Route, useLocation } from "react-router-dom";
import { useGlobalContext } from "./context/context";
import {
  Cards,
  ChangePass,
  Deposit,
  KYC,
  Profile,
  Settings,
  Transactions,
  Transfer,
  UpdateDetails,
  UserMainDashboard,
} from "./pages/UserDashboard/pages";
import PrivateRoutes from "./utils/PrivateRoutes";
import ResetPass from "./pages/ResetPass/ForgetPass";
import { Telegram } from "./components/telegram";

function App() {
  const { pathname } = useLocation();
  const { loading, setLoading } = useGlobalContext();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (pathname) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } else if (pathname && loading === false) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [pathname]);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contacts />} />
        <Route path="/register" element={<Register />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPass />} />
        <Route path="/reset/:id" element={<ResetPass />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/dash/dashboard" element={<UserMainDashboard />} />
          <Route path="/dash/verify" element={<KYC />} />
          <Route path="/dash/transactions" element={<Transactions />} />
          <Route path="/dash/profile" element={<Profile />} />
          <Route path="/dash/settings" element={<Settings />} />
          <Route path="/dash/cards" element={<Cards />} />
          <Route
            path="/dash/settings/update_details"
            element={<UpdateDetails />}
          />
          <Route path="/dash/settings/edit_password" element={<ChangePass />} />
          <Route path="/dash/transfer" element={<Transfer />} />
          <Route path="/dash/deposit" element={<Deposit />} />
        </Route>
      </Routes>
      <Telegram />
    </>
  );
}

export default App;
